<div class="logo">
  <img src="assets/images/logo-white-blue-big.png" alt="Ilerna" *ngIf="!isCollapsed"/>
  <img src="assets/images/logo-simple-blackbg-white-blue.png" alt="Ilerna" *ngIf="isCollapsed"/>
  <span><strong>CORE</strong></span>
</div>

<div class="navigation">
  <app-side-nav-item-group
    title="Formación centros trabajo"
    icon="building"
    [isCollapsed]="isCollapsed"
    *ngIf="userSrv.hasPermission('fct:') | async"
  >
    <app-side-nav-item
      title="Convenios"
      route="fct/contracts"
      *ngIf="userSrv.hasPermission('fct:contracts:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Empresas"
      route="fct/companies"
      *ngIf="userSrv.hasPermission('fct:companies:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Centros"
      route="fct/centers"
      *ngIf="userSrv.hasPermission('fct:centers') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Gestores"
      route="fct/gestors"
      *ngIf="userSrv.hasPermission('fct:gestors-mgmt') | async"
    ></app-side-nav-item>    

    <app-side-nav-item
      title="Periodos Compromiso"
      route="fct/engagement-periods"
      *ngIf="userSrv.hasPermission('fct:engagement-periods:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Estadísticas Compromiso"
      route="fct/engagement-statistics"
      *ngIf="userSrv.hasPermission('fct:engagement-statistics:') | async"
    ></app-side-nav-item>
<!--    <app-side-nav-item-->
<!--      title="Periodos"-->
<!--      route="fct/periods"-->
<!--      *ngIf="userSrv.hasPermission('fct:periods') | async"-->
<!--    ></app-side-nav-item>-->
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Bolsa de trabajo"
    icon="briefcase"
    [isCollapsed]="isCollapsed"
    *ngIf="userSrv.hasPermission('jobs:') | async"
  >
    <app-side-nav-item
      title="Empresas"
      route="jobs/companies"
      *ngIf="userSrv.hasPermission('jobs:companies:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Ofertas"
      route="jobs/offers"
      *ngIf="userSrv.hasPermission('jobs:offers:') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Exámenes"
    icon="list-check"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('exams:') | async"
  >
    <app-side-nav-item
      title="Dashboard"
      route="exams/dashboard"
      *ngIf="this.userSrv.hasPermission('exams:dashboard:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Preguntas"
      route="exams/questions"
      *ngIf="this.userSrv.hasPermission('exams:questions:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Exámenes"
      route="exams/exams"
      *ngIf="this.userSrv.hasPermission('exams:exams_list:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Patrones"
      route="exams/patterns"
      *ngIf="this.userSrv.hasPermission('exams:patterns:') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Reviews"
    icon="comment"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('reviews:reviews:') | async"
  >
    <app-side-nav-item title="Reviews" route="reviews"></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Finanzas"
    *ngIf="this.userSrv.hasPermission('finance:') | async"
    icon="coins"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Consolidación de Pagos"
      route="finance/payments-consolidation"
      *ngIf="this.userSrv.hasPermission('finance:consolidation:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Actualización de estados"
      route="finance/status-update"
      *ngIf="this.userSrv.hasPermission('finance:status-update') | async"
    ></app-side-nav-item>

<!--    <app-side-nav-item-->
<!--      title="Pagos Recurrentes"-->
<!--      route="recurrent-payments"-->
<!--      *ngIf="this.userSrv.hasPermission('finance:recurrent-payments:') | async"-->
<!--      [disabled]="true"-->
<!--    ></app-side-nav-item>-->
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Borrar usuarios"
    icon="user-minus"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('remove-user-data') | async"
  >
    <app-side-nav-item
      title="Borrar por email"
      route="remove-user-data"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Mailing"
    icon="envelope"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('mailing:') | async"
  >
    <app-side-nav-item
      title="Envíados"
      route="mailing/sent"
      *ngIf="this.userSrv.hasPermission('mailing:sent:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Envíos masivos"
      route="mailing/massive-mail"
      *ngIf="this.userSrv.hasPermission('mailing:masive:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Envíos custom"
      route="mailing/custom-sent"
      *ngIf="this.userSrv.hasPermission('mailing:custom-sent') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Black list"
      route="mailing/black-list"
      *ngIf="this.userSrv.hasPermission('mailing:blacklist:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Plantillas"
      route="mailing/templates"
      *ngIf="this.userSrv.hasPermission('mailing:templates:') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Web Ilerna"
    icon="window-maximize"
    *ngIf="this.userSrv.hasPermission('web:') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Blog Links"
      route="web/blog-links"
      *ngIf="this.userSrv.hasPermission('web:blog_links:') | async"
    ></app-side-nav-item>
    <app-side-nav-item
    title="Artículo destacado / Más leidos"
    route="web/featured-posts"
    *ngIf="this.userSrv.hasPermission('web:blog_links:') | async"
  ></app-side-nav-item>
    <app-side-nav-item
      title="Banners / Cintillos"
      route="web/banners-cintillos"
      *ngIf="this.userSrv.hasPermission('web:banners:') | async">
    </app-side-nav-item>

<!--    <app-side-nav-item-subgroup-->
<!--      title="MKT"-->
<!--      icon="folder"-->
<!--      [isCollapsed]="isCollapsed"-->
<!--    >-->
<!--      <app-side-nav-item-->
<!--        title="Blog Links"-->
<!--        route="web/blog-links"-->
<!--        *ngIf="this.userSrv.hasPermission('web:blog_links:') | async"-->
<!--      >-->
<!--        <app-side-nav-item-floating [items]="filterItemsByCategory('blog_links')"></app-side-nav-item-floating>-->
<!--      </app-side-nav-item>-->
<!--      <app-side-nav-item-->
<!--        title="Banners / Cintillos"-->
<!--        route="web/banners-cintillos"-->
<!--        *ngIf="this.userSrv.hasPermission('web:banners:') | async"-->
<!--      >-->
<!--        <app-side-nav-item-floating [items]="filterItemsByCategory('web_banners')"></app-side-nav-item-floating>-->
<!--      </app-side-nav-item>-->
<!--    </app-side-nav-item-subgroup>-->



    <app-side-nav-item
      title="Promociones Homes"
      route="web/promotions"
      *ngIf="this.userSrv.hasPermission('web:banners:promotions') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Agregadores"
      route="web/aggregators"
      *ngIf="this.userSrv.hasPermission('web:aggregators:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Códigos Cursos"
      route="web/course-codes"
      *ngIf="this.userSrv.hasPermission('web:course-codes') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Metadata / FAQs / Enlaces Internos"
      route="web/metadata"
      *ngIf="this.userSrv.hasPermission('web:metadata:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Landings"
      route="web/landings/list"
      *ngIf="this.userSrv.hasPermission('web:landings:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Templates Landings"
      route="web/landings/template-list"
      *ngIf="this.userSrv.hasPermission('web:landings:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Redirecciones / Códigos HTTP"
      route="web/redirections"
      *ngIf="this.userSrv.hasPermission('web:redirections') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Tiendas"
      route="web/shops"
      *ngIf="this.userSrv.hasPermission('web:shops') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Comunicados"
    icon="paper-plane"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('press:messages:') | async"
  >
    <app-side-nav-item title="Comunicados" route="notices"></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Gestión de cursos"
    icon="pencil"
    *ngIf="this.userSrv.hasPermission('course_management:') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Bolsa de módulos"
      route="courses/modules"
      *ngIf="this.userSrv.hasPermission('course_management:modules:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Ciclos"
      route="courses/programs"
      *ngIf="this.userSrv.hasPermission('course_management:courses:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Configuración"
      route="courses/configuration"
      *ngIf="this.userSrv.hasPermission('course_management:configuration:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Unidades de competencia"
      route="courses/competency-units"
      *ngIf="this.userSrv.hasPermission('course_management:competency-units') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Configuración académica"
    icon="graduation-cap"
    *ngIf="this.userSrv.hasPermission('academic_configuration:') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Periodos formativos"
      route="academic/sections"
      *ngIf="
        this.userSrv.hasPermission('academic_configuration:training_periods:')
          | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Estados periodos formativos"
      route="academic/sections-status"
      *ngIf="
        this.userSrv.hasPermission('academic_configuration:status:') | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Configuración de grupos"
      route="academic/groups/configurations"
      *ngIf="
        this.userSrv.hasPermission(
          'academic_configuration:group_configuration:'
        ) | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Tipos de grupos"
      route="academic/groups/types"
      *ngIf="
        this.userSrv.hasPermission('academic_configuration:group_type:') | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Profesores"
      route="academic/teachers"
      *ngIf="
        this.userSrv.hasPermission('academic_configuration:teachers:') | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Configuración"
      route="academic/configuration"
      *ngIf="
        this.userSrv.hasPermission('academic_configuration:configuration:')
          | async
      "
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Centros"
    icon="school"
    *ngIf="this.userSrv.hasPermission('centers:') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Centros formativos"
      route="centers/list"
      *ngIf="this.userSrv.hasPermission('centers:formative_centers:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Trabajadores"
      route="centers/workers"
      *ngIf="this.userSrv.hasPermission('centers:formative_centers:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Festivos Centros"
      route="centers/holidays"
      *ngIf="this.userSrv.hasPermission('centers:holidays') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Aulas"
      route="centers/classrooms"
      *ngIf="this.userSrv.hasPermission('centers:classrooms:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Tipos de Aulas"
      route="centers/classroom-types"
      *ngIf="this.userSrv.hasPermission('centers:classrooms:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Disponibilidad de Aulas"
      route="centers/classrooms-availability"
      *ngIf="
        this.userSrv.hasPermission('centers:classrooms_availability:') | async
      "
    ></app-side-nav-item>

    <app-side-nav-item
      title="Materiales"
      route="centers/materials"
      *ngIf="this.userSrv.hasPermission('centers:materials:') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Contenidos"
    icon="book"
    *ngIf="this.userSrv.hasPermission('contents:') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item title="Contenidos" route="/contents"></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Logística"
    icon="truck-fast"
    [isCollapsed]="isCollapsed"
    *ngIf="this.userSrv.hasPermission('logistics:') | async"
  >
    <app-side-nav-item
      title="Administrar Libros"
      route="/logistics/books-manager"
      *ngIf="this.userSrv.hasPermission('logistics:books:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Configurar Stocks"
      route="/logistics/stocks-manager"
      *ngIf="this.userSrv.hasPermission('logistics:stocks:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Pedidos Enviados"
      route="/logistics/sent-orders"
      *ngIf="this.userSrv.hasPermission('logistics:sentorders:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Pedidos Por Tramitar"
      route="/logistics/orders-to-be-processed"
      *ngIf="this.userSrv.hasPermission('logistics:toprocess:') | async"
    ></app-side-nav-item>

    <app-side-nav-item
      title="Cola de impresión"
      route="/logistics/print-queue"
      *ngIf="this.userSrv.hasPermission('logistics:printqueue:') | async"
    ></app-side-nav-item>
  </app-side-nav-item-group>

  <app-side-nav-item-group
    title="Blacklist Llamadas"
    icon="phone-slash"
    *ngIf="this.userSrv.hasPermission('sales:blacklist') | async"
    [isCollapsed]="isCollapsed"
  >
    <app-side-nav-item
      title="Blacklist Llamadas"
      route="blacklist-calls"
    ></app-side-nav-item>
  </app-side-nav-item-group>
</div>

<div class="collapse-sidenav-btn" (click)="toggleCollapse()">
  <i class="fa-solid fa-angles-down" *ngIf="!isCollapsed"></i>
  <i class="fa-solid fa-angles-up" *ngIf="isCollapsed"></i>
</div>
